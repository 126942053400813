import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { companyLogo, apiUrl, headerconfig } from "../Settings/Config";
import logo from "../../common/images/epicpay.png";
import user from "../../common/images/user.svg";
import homeIcon from "../../common/images/homelg.png";
import nav from "../../common/images/navigation.svg";
import cookie from "react-cookies";
import invoice from "../../common/images/invoice-icon.svg";
import vouc from "../../common/images/voucher-icon.svg";
import wallet from "../../common/images/wallet.svg";
import back from "../../common/images/back-arrow.svg";
import walletlight from "../../common/images/wallet.svg";
import epicpay from "../../common/images/epicpay.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toppageData: [],
      menuActive: "hide",
    };
  }

  componentDidMount() {
    var customerId = cookie.load("UserId") || "";
    if (customerId !== "") {
      this.checkAuth(customerId);
    }
  }
  checkAuth(customerId) {
    var urlShringTxt = apiUrl + "customer/checkingauth/?ID=" + customerId;
    axios.get(urlShringTxt, headerconfig).then((res) => {
      if (res.data.status !== "ok") {
        this.props.prntPagePrps.history.push("/logout");
      }
    });
  }
  componentWillReceiveProps(PropsData) {
    if (this.state.toppageData !== PropsData.mainpagestate) {
      this.setState({ toppageData: PropsData.mainpagestate });
    }
  }

  openMenuFun(event) {
    event.preventDefault();
    let menuActive = this.state.menuActive;
    if (menuActive === "hide") {
      this.setState({ menuActive: "open" });
      $(".side-bar-maincls").addClass("open");
    } else {
      this.setState({ menuActive: "hide" });
      $(".side-bar-maincls").removeClass("open");
    }
  }

  goToNavPage(activepage, event) {
    event.preventDefault();
    let pageTxt = "";
    var backLink = this.props.mainpagestate?.backLink || "";
    console.log(backLink, 'backLink');
    if (backLink !== "") {
      pageTxt = backLink;
    } else if (activepage === "Top Up" || activepage === "PointsRedeem") {
      pageTxt = "myaccount";
    } else if (activepage === "Vouchers") {
      let actionfrom =
        Object.keys(this.props.mainpagestate).length > 0
          ? this.props.mainpagestate.actionfrom
          : "";
      pageTxt = actionfrom === "rewards" ? "rewards" : "myaccount";
    } else if (
      activepage === "VouchersDetail" ||
      activepage === "VouchersRedeem"
    ) {
      pageTxt = "vouchers";
    }else if (activepage === "Subscription Details") {
      pageTxt = "subscription-history";
    }

    this.props.prntPagePrps.history.push("/" + pageTxt);
  }

  showVoucher(tabTxt, event) {
    event.preventDefault();
    cookie.save("vouchers_show", tabTxt, { path: "/" });
    this.props.prntPagePrps.history.push("/vouchers");
  }

  gotoRedeemFun(event) {
    event.preventDefault();
    this.props.history.push("/scanqrcode");
  }

  render() {
    let activepage =
      Object.keys(this.props.mainpagestate).length > 0
        ? this.props.mainpagestate.current_page
        : "";
    return (
      <>
        <header className="header-main">
          <div className="header-action header-center-txt">
            <div className="container">
              {activepage === "My Account" || activepage === "Epic" ? (
                <>
                  <div className="ha-lhs">
                    {activepage === "Epic" ? (
                      <a href="/">
                        <img src={epicpay} alt="Epic Pay" />
                      </a>
                    ) : (
                      <Link to={"/home"} title="Home">
                        {companyLogo !== "" ? (
                          <img
                            className="dynmic-cmp-logo"
                            src={companyLogo}
                            alt="logo"
                          />
                        ) : (
                          <img src={logo} alt="Epic Pay" />
                        )}
                      </Link>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="ha-lhs-arrow">
                    {activepage !== "Epic" &&
                      ((this.props.mainpagestate.analyticsType === "" &&
                        this.props.mainpagestate.influencerID === "") ||
                        this.props.mainpagestate.customerId !== "") && (
                        <a
                          href="/"
                          onClick={this.goToNavPage.bind(this, activepage)}
                        >
                          <img src={back} alt="Go Back" />
                        </a>
                      )}
                  </div>

                  <div className="ha-middle">
                    {activepage !== "VouchersRedeem" ? activepage : ""}
                  </div>
                </>
              )}

              <div className="ha-rhs">
                {((this.props.mainpagestate.analyticsType === "" &&
                  this.props.mainpagestate.influencerID === "") ||
                  this.props.mainpagestate.customerId !== "") && (
                  <ul>
                    {activepage === "My Account" && (
                      <li className="profile-user home-linkcls">
                        <Link to={"/home"} className="home-btn" title="company">
                          <img src={homeIcon} alt="Home" />
                        </Link>
                      </li>
                    )}
                    <li className="profile-user">
                      <a href="/">
                        <img src={user} alt="user" />
                      </a>
                    </li>
                    <li className="navsbar">
                      <a
                        href="/"
                        onClick={this.openMenuFun.bind(this)}
                      >
                        <img src={nav} alt="nav" />
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </header>

        <div className={"side-bar full-height side-bar-maincls"}>
          <div className="container">
            <div className="three-nav">
              <Link
                to={"/refpage/myaccount"}
                className="button rv-btn"
                title="Myaccount"
              >
                {" "}
                <img src={walletlight} alt="My Account" /> My Account{" "}
              </Link>
              <Link
                to={"/refpage/vouchers"}
                className="button rv-btn"
                title="Vouchers"
              >
                {" "}
                <img src={vouc} alt="Voucher" /> Vouchers{" "}
              </Link>
              <Link
                to={"/refpage/topup"}
                title="Buy Credits"
                className="button rc-btn"
              >
                <img src={wallet} alt="Buy Credits" /> Buy Credits
              </Link>
              <Link
                to={"/refpage/history"}
                title="View Daily Transactions"
                className="button vdt-btn"
              >
                {" "}
                <img src={invoice} alt="View History" /> View History
              </Link>
              <Link
                to={"/refpage/subscription-history"}
                title="View Daily Transactions"
                className="button vdt-btn"
              >
                {" "}
                <img src={invoice} alt="View My Suscription" /> View My
                Suscription
              </Link>
              <Link to={"/logout"} title="Logout" className="button vdt-btn">
                {" "}
                <img src={invoice} alt="Logout" /> Logout{" "}
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
